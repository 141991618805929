import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

//import TPF_Logo_Color from '../assets/images/TPF_Logo_Color.png'
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';


const Elements = (props) => (
    <Layout>
        <Helmet>
            <title>Bricktown Brewery Fundraising Event</title>
            <meta name="description" content="Elements Page" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Bricktown Brewery</h1>
                        <h2>Support your local teachers </h2>
                    </header>
                    <div className="grid-wrapper">
                        <div className="col-3">
                            {/*<img src={TPF_Logo_Color} alt="Teachers Plus Fund Logo" />*/}
                            <Img fluid={props.data.tpfLogoColor.childImageSharp.fluid} />
                        </div>
                        <div className="col-5">
                        <h4>Monday Sept. 23, 2019</h4>
                        <h4>7301 SE 29TH ST. - MIDWEST CITY, OK - 73110</h4>
                        <h4>Kids eat free with the purchase of an adult meal</h4>
                        </div>
                            
                        <div className="col-3">
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="QK7B8BWS7CVLG" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                            </form>             
                        </div>
                    </div>


                </div>
            </section>

        </div>

    </Layout>
)

export default Elements

export const pageQuery = graphql`
query {
    tpfLogoColor: file(relativePath: { eq: "TPF_Logo_Color.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;